<template>
  <div>
    <div class="d-flex align-items-center flex-wrap th-mb-12">
      <h5
        class="th-m-0 body2"
        v-if="input.label"
        v-html="$translation.t(input.label)"
      ></h5>
      <small
        v-show="inputValuesCount > 3"
        :class="{ 'th-ml-12-md': input.label }"
      >
        <a
          href="javascript:;"
          @click="triggerSelect"
          v-html="$translation.t(selectAll ? 'Unselect all' : 'Select all')"
        ></a>
      </small>
    </div>
    <v-form ref="multiSelectForm">
      <div class="row th-px-12">
        <div
          class="col-12 th-px-4 th-py-0 th-my-4"
          :class="{ 'col-md-4 col-lg-3': input.inline }"
          v-for="(label, value, index) in input.values"
          :key="index"
        >
          <div class="d-flex flex-column flex-md-row align-items-md-center">
            <v-checkbox
              class="th-my-0"
              v-bind="attributes(label, value)"
              v-model="model"
              hide-details="auto"
            ></v-checkbox>
            <w-tooltip
              class="th-ml-8-md"
              v-if="labelTooltip(label)"
              :content="$translation.t(labelTooltip(label))"
            />
          </div>
          <div class="th-pl-32" v-if="isPlainObject(label)">
            <v-text-field
              v-for="(subinput, subinputKey) in label.inputs.filter(
                (subinput) => conditional(subinput)
              )"
              :class="subinput.class"
              :key="subinputKey"
              :label="$translation.t(subinput.label)"
              outlined
              v-model="additionalModel[subinput.model]"
              :rules="subinput.rules || []"
              hide-details="auto"
            >
            </v-text-field>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import { isPlainObject, isArray } from "lodash";
import WTooltip from "@/components/GeneralComponents/WTooltip";
import { isTrue } from "../../utils/questionGeneratorHelpers";

export default {
  name: "MultiSelectField",
  components: { WTooltip },
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: { default: false },
  },
  data: () => ({
    model: [],
    selectAll: false,
    additionalModel: {},
  }),
  mounted() {
    if (isPlainObject(this.value)) {
      this.model = this.objectToArrayOfValues(this.value);

      Object.values(this.input.values)
        .filter((options) => isPlainObject(options) && options.inputs)
        .map(({ inputs }) =>
          inputs.map((input) => {
            if (this.value[input.model] || input.default) {
              this.$set(
                this.additionalModel,
                input.model,
                this.value[input.model] ?? input.default
              );
            }
            return input.model;
          })
        )
        .flat();
    } else if (Array.isArray(this.value)) {
      this.model = [...(this.value || [])];
    } else {
      this.$set(this, "model", []);
    }

    if (!this.model.length && this.input.default)
      this.model = this.objectToArrayOfValues(this.input.default);
  },
  computed: {
    inputValuesCount() {
      return isPlainObject(this.input.values)
        ? Object.values(this.input.values).length
        : this.input.values.length;
    },
    isSelectedAll() {
      return this.model.length >= this.inputValuesCount;
    },
    optionsInArray() {
      return !!isArray(this.input.values);
    },
    optionsKeys() {
      return this.optionsInArray
        ? this.input.values
        : Object.keys(this.input.values);
    },
    finalModel() {
      if (
        Object.keys(this.additionalModel).length ||
        this.input.result === "object"
      ) {
        let modelToObject = {};

        // this.model.forEach(value =>
        //     Object.assign(modelToObject, { [value]: true })
        // );

        this.optionsKeys.forEach((key) => {
          Object.assign(modelToObject, {
            [key]: this.getValueIfExistsInModel(this.model, key),
          });
        });

        return {
          ...modelToObject,
          ...(this.additionalModel || {}),
        };
      }

      return this.model;
    },
  },
  methods: {
    triggerSelect() {
      this.selectAll = !this.selectAll;
      if (this.selectAll || this.isSelectedAll) {
        this.model = isPlainObject(this.input.default)
          ? Object.entries(this.input.default)
              .map(([value, label]) => (isTrue(label) ? value : null))
              .filter((v) => v)
          : this.input.default;
      }
    },
    isPlainObject,
    objectToArrayOfValues(object) {
      return Object.entries(object)
        .filter(([key, value]) => this.input.values[key] && !!value)
        .map(([key]) => key);
    },
    getValueIfExistsInModel(model, key) {
      if (!model) return false;

      if (Array.isArray(model)) {
        return model?.includes(key);
      } else {
        // object
        return model[key];
      }
    },
    attributes(label, value) {
      const checkboxValue = this.optionValue(value, this.getRealLabel(label));

      if (isPlainObject(label)) {
        let finalAttributesObject = label;

        if (!finalAttributesObject.value)
          finalAttributesObject.value = checkboxValue;

        return {
          ...finalAttributesObject,
          label: this.translateLabel(finalAttributesObject.label),
          value: finalAttributesObject.value,
        };
      }

      return {
        label: this.translateLabel(label),
        value: checkboxValue,
      };
    },
    translateLabel(label) {
      if (this.input.noTrans) return label;

      return this.$translation.t(label);
    },
    getRealLabel(label) {
      return isPlainObject(label) ? label.label : label;
    },
    isDisabled(label) {
      return isPlainObject(label) ? label.disabled ?? false : false;
    },
    conditional(input) {
      return input.condition ? input.condition(this.model) : true;
    },
    optionValue(value, label) {
      return this.optionsInArray ? label : value;
    },
    labelTooltip(label) {
      return isPlainObject(label) ? label.tooltip : null;
    },
    validate() {
      return this.$refs.multiSelectForm.validate() &&
        typeof this.input.validate === "function"
        ? this.input.validate({
            model: this.model,
            additionalModel: this.additionalModel,
          })
        : true;
    },
  },
  watch: {
    model() {
      this.$emit("input", this.finalModel);

      setTimeout(() => {
        this.selectAll = this.isSelectedAll;
      }, 50);
    },
    additionalModel: {
      handler: function () {
        this.$emit("input", this.finalModel);
      },
      deep: true,
    },
    selectAll(newValue) {
      if (newValue) {
        if (isPlainObject(this.input.values)) {
          this.model = Object.entries(this.input.values).map(
            ([value, label]) => this.attributes(label, value)?.value
          );
        } else {
          this.model = this.input.values.map(
            (label, value) => this.attributes(label, value)?.value
          );
        }
      }
    },
  },
};
</script>
